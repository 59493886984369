import React, { Dispatch } from 'react'

import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  TextField as MuiTextField,
  createTheme,
  ThemeProvider,
} from '@mui/material'

interface SearchFieldProps {
  label?: string
  className?: string
  placeholder?: string
  value: string
  onChange: Dispatch<string>
  autoFocus?: boolean
}

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#4f46e5',
      light: '#4f46e5',
      dark: '#818cf8',
    },
    secondary: {
      main: '#6b7280',
    },
    error: {
      main: '#ef4444',
    },
    background: {
      default: '#fff',
    },
  },
  shape: {
    borderRadius: 6,
  },
})

export const SearchField: React.FC<SearchFieldProps> = ({
  label,
  placeholder = 'Search',
  value,
  onChange,
  className,
  autoFocus = false,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiTextField
        size="small"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onChange(event.target.value)
        }}
        className={`${className} w-full`}
        type="search"
        placeholder={placeholder}
        label={label}
        value={value}
        InputProps={{
          startAdornment: (
            <MagnifyingGlassIcon className="h-6 w-6 mr-2 text-gray-400" />
          ),
        }}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
    </ThemeProvider>
  )
}

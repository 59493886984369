import { captureException } from '@sentry/browser'
import { isLocal } from 'api/src/common/enums'
import mixpanel from 'mixpanel-browser'
import ReactGA from 'react-ga4'

import { useAuth } from 'web/src/Providers'

// no info sent if not using correct category
enum Categories {
  'Ad Generator' = 'Ad Generator',
  'Announcer' = 'Announcer',
  'Audit Log' = 'Audit Log',
  'Benchmarking' = 'Benchmarking',
  'Course Reporting' = 'Course Reporting',
  'Goals' = 'Goals',
  'HubDash' = 'HubDash',
  'Knowledge Base' = 'Knowledge Base',
  'Learner' = 'Learner',
  'MFA' = 'MFA',
  'Notifications' = 'Notifications',
  'NPS' = 'NPS',
  'Pages' = 'Pages',
  'People' = 'People',
  'Process Maps' = 'Process Maps',
  'Search' = 'Search',
  'Sentiment Analysis' = 'Sentiment Analysis',
  'Settings' = 'Settings',
  'Super Admin' = 'Super Admin',
  'Tech Stack / Form Links' = 'Tech Stack / Form Links',
  'Uncategorized' = 'Uncategorized',
}

export interface GATrackerType {
  category: string
  eventName?: string
}

interface AppendLabelType {
  [key: string]: string | number | boolean
}

export type TrackEventFunction = (
  category: string,
  action: string,
  AppendLabel?: AppendLabelType,
) => void

const useAnalytics = (): { trackEvent: TrackEventFunction } => {
  const trackEvent = (
    category: string,
    action: string,
    AppendLabel?: object,
  ) => {
    const isGHAction = process.env.IS_GH_ACTION === 'true'

    if (isLocal || isGHAction) return

    if (!(category in Categories)) {
      captureException(
        new Error(`Mixpanel Category ${category} not in Categories`),
      )
      return
    }
    const dateObj = new Date()
    // googleAnalytics already collects event YYYYMMDDHH, we only need minutes and seconds

    const initLabel = {
      ms: `${dateObj.getMinutes()}:${dateObj.getSeconds()}`,
    }

    let label: string
    if (AppendLabel) {
      label = JSON.stringify({
        ...initLabel,
        ...AppendLabel,
      })
    } else {
      label = JSON.stringify(initLabel)
    }

    ReactGA.event({
      category: category,
      action,
      label,
    })

    if (action !== 'tracking') {
      // don't track mixpanel page views twice
      mixpanel.track(action, {
        eventCategory: category,
        ...AppendLabel,
      })
    }
  }

  return { trackEvent }
}

export default useAnalytics
